
import { defineComponent,ref } from "vue";
import axios from "axios";
import store from "@/store";
import moment from "moment";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "tourApply",
  components: {
    //Datatable,
  },
  mounted() {
    if (!store.state.uid) {
      ElMessage.error(this.$t("pleaseLogin"));
      this.$router.push({name: 'login'});
    }
    this.getTourData()
  },
  data() {
    return {
      playerItems: [],
      tourType:'',
      apiMessage:'',
      checkedTour: false,
      tourList: [],
      memo:'',
      mobile:'',
      gender:{},
      area:{},
      mobileError: '',
      partnerUsername: '',
      partnerUsernameError: '',
      genderError: false,
      applySpinner: false,
      emptyPartner: false,
      readOnly: false,
      showApplyBtn: false,
      totalFee: {
        total: 0,
        applyFee: 0,
        deposit: 0,
        credit: 0
      }
    };
  },
  methods: {
    countFee(){
      var applyFee = 0;
      this.tourList.forEach((item,i) => {
        if (item['isChecked'] === true) { //If there is checked
          applyFee = applyFee + Number(item['applyfee'])
          this.checkedTour = true
        }
      });
      this.totalFee.applyFee = Number(applyFee)
      this.totalFee.total = this.totalFee.applyFee + this.totalFee.deposit - this.totalFee.credit
    },
    checkInput() {
      //console.log("checkInputs")
      this.countFee()
      this.genderError = false
      this.checkedTour = false
      this.emptyPartner = false
      this.tourList.forEach((item,i) => {
        
        if (item['isChecked'] === true) { //If there is checked
          (item as { partnerUsernameError: string }).partnerUsernameError = "";
          this.checkedTour = true

          //Check Gender
          if ((item['name_en'] as string).includes('Women') && this.gender !=2) {
            this.genderError = true
            return false
          }
          //Check Partner
          if ((item['name_en'] as string).includes('Double') && !item['partnerUsername']) {
            (item as { partnerUsernameError: string }).partnerUsernameError = "requiredPartnerUsername";
            this.emptyPartner = true
            return false
          } else {
            (item as { partnerUsernameError: string }).partnerUsernameError = "";
          }
        }
      });

      //If no tour is checked
      if (this.checkedTour == false) {
        return false
      } else if (!!this.genderError) {
        return false
      } else if (this.emptyPartner) {
        return false
      }
        
      return true
    },
    applyModify() {
      this.readOnly = false
      this.showApplyBtn = true
    },
    applyTour() {
      if (!this.checkInput()) { //Check form
        //console.log("checkInput() false")
        if (this.checkedTour == false) {
          ElMessage.error(this.$t("emptyTour"));
        } else if (this.genderError == true) {
          ElMessage.error(this.$t("applyGenderError"));
        } else if (this.emptyPartner == true) {
          ElMessage.error(this.$t("requiredPartnerUsername"));
        }
        
      } else if (!this.mobile) {
        this.mobileError = 'requiredMobile';
        ElMessage.error(this.$t("requiredMobile"));
        (this.$refs.refMobile as any).focus()
      } else if (this.tourType.includes('double') && !this.partnerUsername) {
        this.partnerUsernameError = 'requiredPartnerUsername';
        ElMessage.error(this.$t("requiredPartnerUsername"));
        (this.$refs.refPartnerUsername as any).focus()
      } else {
        this.applySpinner = true;
        this.readOnly = true
        //console.log(this.tourList)
        axios
          .post(
            process.env.VUE_APP_API_URL + "&mode=jwt&tab=tourapply&action=apply&tourid=" + this.$route.params.tour_id+"&eventid="+this.$route.params.event_id,
            JSON.stringify({tourList: this.tourList, mobile: this.mobile, region: this.area, memo: this.memo, type: this.tourType, gender: this.gender})
          )
          .then((response) => {
            //console.log(response.data.data)
            this.applySpinner = false;
            this.tourList = response.data.data.tourList
            this.readOnly = false
            if (response.data.message == 'applySuccess') {
              this.readOnly = true
              this.showApplyBtn = false
              ElMessage.success(this.$t("appliedTitle"));
              this.$router.push({name: 'tourPay'});
              //this.getTourData()
            } else if (response.data.message == 'partnerReg') {
              ElMessage.error(this.$t("partnerReg"));
            } else if (response.data.message == 'fakeCantApply') {
              ElMessage.error(this.$t("fakeCantApply"));
            } else {
              //ElMessage.error(this.$t(response.data.message));
              ElMessage.error(this.$t(response.data.message));
            }
          });
      }
    },
    getTourData() {
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=tourapply&action=read&tourid=" + this.$route.params.tour_id+"&eventid="+this.$route.params.event_id,
          JSON.stringify({uid: store.state.uid})
        )
        .then((response) => {
          //Add checkbox property
          //console.log(response)
          
          this.apiMessage = response.data.message
          if (response.data.message=='applyStart' || response.data.message=='applied') {
            response.data.data.tourList.forEach(tour => {
              if (tour.isChecked == 'true') {
                tour.isChecked = true
                this.readOnly = true
              }
            });
            if (this.readOnly != true) { this.showApplyBtn = true}
            this.tourList = response.data.data.tourList
            this.totalFee.credit = Number(response.data.data.extcredits8)
            this.totalFee.deposit = Number(response.data.data.deposit)
            this.memo = response.data.data.remark
            this.mobile = response.data.data.mobile
            this.area = Number(response.data.data.region)
            this.tourType = (response.data.data.type)
            this.gender = Number(response.data.data.gender)
          } 
          if (response.data.message=='applied') {
            ElMessage.success(this.$t("appliedTitle"));
            this.$router.push({name: 'tourPay'});
          }
          this.countFee()
        });
    },
    checkMobile() {
      if (!this.mobile) {
        this.mobileError = 'requiredMobile';
        return false
      } else {
        this.mobileError = '';
        return true
      }
    },
    checkPartner(partnerUsername, partnerUsernameError) {
      partnerUsernameError = '';
      if (!partnerUsername) {
        partnerUsernameError = 'requiredPartnerUsername';
        return false
      } else {
        partnerUsernameError = '';
        return true
      }
    },
  },
  watch: {
    detectTourid() {
      this.getTourData()
    },
  },
  computed: {
    uidValue() {
      return store.state.uid;
    },
    detectTourid() {
      return Number(this.$route.params.tour_id);
    },
    getOptionRegion() {
      return [
        { label: this.$t("region0"), value: 0 },
        { label: this.$t("region1"), value: 1 },
        { label: this.$t("region2"), value: 2 },
        { label: this.$t("region3"), value: 3 },
        { label: this.$t("region4"), value: 4 },
        { label: this.$t("region5"), value: 5 },
        { label: this.$t("region6"), value: 6 },
        { label: this.$t("region7"), value: 7 },
        { label: this.$t("region8"), value: 8 },
        { label: this.$t("region9"), value: 9 },
        { label: this.$t("region10"), value: 10 },
        { label: this.$t("region11"), value: 11 },
        { label: this.$t("region12"), value: 12 },
        { label: this.$t("region13"), value: 13 },
        { label: this.$t("region14"), value: 14 },
        { label: this.$t("region15"), value: 15 },
        { label: this.$t("region16"), value: 16 },
        { label: this.$t("region17"), value: 17 },
        { label: this.$t("region18"), value: 18 },
      ];
    },
    getOptionGender() {
      return [
        { label: this.$t("genderOther"), value: 0 },
        { label: this.$t("genderMale"), value: 1 },
        { label: this.$t("genderFemale"), value: 2 },
      ];
    },
  },
});
