
import { defineComponent, ref } from "vue";
//import { translate } from "element-plus";
import axios from "axios";
import store from "@/store";
import moment from "moment";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "customers-listing",
  components: {
    //Datatable,
  },
  mounted() {
    this.getPlayersData()
  },
  data() {
    return {
      playerItems: [],
      tourType:'',
      withdarwUser: '',
      addPlayer: '',
      addDoublePlayer1: '',
      addDoublePlayer2: '',
    };
  },
  computed: {
    uidValue() {
      return store.state.uid;
    },
    detectTourid() {
      return Number(this.$route.params.tour_id);
    }
  },
  methods: {
    getPlayersData() {
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=pub&tab=tourplayers&tourid=" + this.$route.params.tour_id+"&eventid="+this.$route.params.event_id,
          JSON.stringify({uid: store.state.uid})
        )
        .then((response) => {
          //console.log(response.data)
          this.playerItems = response.data.data;
          this.tourType = response.data.type
        });
    },
    addSingle(uid) {
      if (!uid) {
        ElMessage.error(this.$t("error"));
      } else {
        axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=cp&action=addSingle&applyUid=" + uid + "&tourid=" + this.$route.params.tour_id+"&eventid="+this.$route.params.event_id,
          JSON.stringify({uid: store.state.uid})
        )
        .then((response) => {
          if (response.data.result == "success") {
            ElMessage.success(this.$t("success"));
          } else {
            ElMessage.error(response.data.result);
          }
          this.getPlayersData()
        });
      }
    },
    addDouble(uid1,uid2) {
      if (!uid1 || !uid2) {
        ElMessage.error(this.$t("error"));
      } else {
        axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=cp&action=addDouble&uid1="+uid1 +"&uid2="+uid2+"&tourid=" + this.$route.params.tour_id+"&eventid="+this.$route.params.event_id,
          JSON.stringify({uid: store.state.uid})
        )
        .then((response) => {
          if (response.data.result == "success") {
            ElMessage.success(this.$t("success"));
          } else {
            ElMessage.error(response.data.result);
          }
          this.getPlayersData()
        });
      }
    },
    paidClick(uid) {
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=cp&action=applyPaid&applyUid=" + uid + "&tourid=" + this.$route.params.tour_id,
          JSON.stringify({uid: store.state.uid})
        )
        .then((response) => {
          if (response.data.result == "success") {
            ElMessage.success(this.$t("adminPaidSuccess"));
          } else {
            ElMessage.error(this.$t("error"));
          }
          this.getPlayersData()
        });
    },
    paidDepoClick(uid) {
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=cp&action=applyPaidDepo&applyUid=" + uid + "&tourid=" + this.$route.params.tour_id,
          JSON.stringify({uid: store.state.uid})
        )
        .then((response) => {
          if (response.data.result == "success") {
            ElMessage.success(this.$t("adminPaidSuccess"));
          } else {
            ElMessage.error(this.$t("error"));
          }
          this.getPlayersData()
        });
    },
    withdrawClick(uid) {
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=cp&action=applyWithdraw&withdrawUid=" + uid + "&tourid=" + this.$route.params.tour_id,
          JSON.stringify({uid: store.state.uid})
        )
        .then((response) => {
          if (response.data.result == "success") {
            ElMessage.success(this.$t("adminWithdrawSuccess"));
          } else {
            ElMessage.error(this.$t("error"));
          }
          document.getElementById('close')!.click();
          this.getPlayersData()
        });
    }
  },
  watch: {
    detectTourid() {
      this.getPlayersData()
    }
  }
});

